"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useOriginalPrice", {
    enumerable: true,
    get: function() {
        return useOriginalPrice;
    }
});
const _react = require("react");
function useOriginalPrice(channel, originalPrice) {
    return (0, _react.useMemo)(()=>{
        const priceChannel = originalPrice === null || originalPrice === void 0 ? void 0 : originalPrice[channel];
        if (priceChannel) {
            var _appliedRules_;
            const { appliedRules } = priceChannel;
            const idx = appliedRules === null || appliedRules === void 0 ? void 0 : appliedRules.findIndex((rule)=>rule.title === "Price Override" || rule.title === `${channel} Price Override`);
            if (idx && idx > 0) {
                var _appliedRules_1;
                return appliedRules === null || appliedRules === void 0 ? void 0 : (_appliedRules_1 = appliedRules[idx - 1]) === null || _appliedRules_1 === void 0 ? void 0 : _appliedRules_1.price;
            }
            return appliedRules === null || appliedRules === void 0 ? void 0 : (_appliedRules_ = appliedRules[appliedRules.length - 1]) === null || _appliedRules_ === void 0 ? void 0 : _appliedRules_.price;
        }
    }, [
        originalPrice,
        channel
    ]);
}
