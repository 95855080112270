"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useSelectedDates", {
    enumerable: true,
    get: function() {
        return useSelectedDates;
    }
});
const _reactquery = require("@tanstack/react-query");
const _react = require("react");
const _price = require("../../models/price");
const _react1 = require("../../react");
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function useSelectedDates(listing, startDate, endDate, channels) {
    const http = (0, _react.useContext)(_react1.HTTPContext);
    const { data, isLoading } = (0, _reactquery.useQuery)({
        queryKey: [
            listing._id,
            startDate,
            endDate
        ],
        gcTime: 0,
        queryFn: async ()=>{
            const url = `/getCalendar/${listing._id}/${startDate}/${endDate}`;
            const { data } = await http.get(url);
            return data;
        }
    });
    return (0, _react.useMemo)(()=>{
        if (isLoading) {
            return undefined;
        }
        const raw = data === null || data === void 0 ? void 0 : data.prices;
        if (!raw) {
            return null;
        }
        const prices = raw.map((elem)=>(0, _price.formatChannelPrices)(elem, listing.currencySymbol));
        const first = prices[0];
        const channelsHasPriceCalculation = channels.filter((channel)=>{
            var _priceChannel_appliedRules;
            const priceChannel = first[channel];
            return priceChannel === null || priceChannel === void 0 ? void 0 : (_priceChannel_appliedRules = priceChannel.appliedRules) === null || _priceChannel_appliedRules === void 0 ? void 0 : _priceChannel_appliedRules.length;
        });
        return {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            price: first,
            prices,
            channelsHasPriceCalculation,
            rules: prices.map((elem)=>channelsHasPriceCalculation.map((channel)=>{
                    var _elem_channel_appliedRules, _elem_channel;
                    return (_elem_channel = elem[channel]) === null || _elem_channel === void 0 ? void 0 : (_elem_channel_appliedRules = _elem_channel.appliedRules) === null || _elem_channel_appliedRules === void 0 ? void 0 : _elem_channel_appliedRules.map((_param)=>{
                        var { _id } = _param, rest = _object_without_properties(_param, [
                            "_id"
                        ]);
                        return rest;
                    });
                }))
        };
    }, [
        startDate,
        endDate,
        listing.currencySymbol,
        channels,
        data,
        isLoading
    ]);
}
