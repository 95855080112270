"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "useDebounce", {
    enumerable: true,
    get: function() {
        return useDebounce;
    }
});
const _react = require("react");
function useDebounce(value, delay, callback) {
    const callbackRef = (0, _react.useRef)();
    callbackRef.current = callback;
    (0, _react.useEffect)(()=>{
        const timer = setTimeout(()=>{
            var _callbackRef_current;
            (_callbackRef_current = callbackRef.current) === null || _callbackRef_current === void 0 ? void 0 : _callbackRef_current.call(callbackRef, value);
        }, delay);
        return ()=>{
            clearTimeout(timer);
        };
    }, [
        value,
        delay
    ]);
}
